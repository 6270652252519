import React from 'react';
import '../assets/styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="logo" onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }}>
        <img src="/Logo4.png" alt="Finn Tech Logo" />
      </div>
      <nav className="nav-links">
        <a href="/login">Login</a>
        <a href="/signup">Sign Up</a>
        <a href="/blogs">Blog</a>
        <a href="/contact">Contact</a>
        <a href="/pricing">Pricing</a>
        <a href="/about">About</a>
      </nav>
      <div className="social-media">
        <a href="https://www.youtube.com/@TheFinnTech" target="_blank" rel="noopener noreferrer">
          <img src="/youtube-icon.png" alt="YouTube" />
        </a>
        <a href="https://www.linkedin.com/company/finn-tech-llc/" target="_blank" rel="noopener noreferrer">
          <img src="/linkedin-icon.png" alt="LinkedIn" />
        </a>
        <a href="https://x.com/TheFinnTech" target="_blank" rel="noopener noreferrer">
          <img src="/x-social-media-logo-icon.webp" alt="X" />
        </a>
        <a href="https://www.instagram.com/thefinntech/?hl=en" target="_blank" rel="noopener noreferrer">
          <img src="/instagram.webp" alt="Instagram" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;