import React, { useContext, useState, useCallback, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import About from './pages/About';
import Pricing from './pages/Pricing';
import Contact from "./pages/Contact";
import SocialMedia from './pages/SocialMedia';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard"
import AuthLogin from "./profile/auth-login";
import AccessDenied from "./pages/AccessDenied"
import Blogs from "./pages/Blogs";
import BlogPost from "./pages/BlogPost"; // You'll need to create this component
import AddCharts from "./pages/AddCharts";
import LoggedInBlogs from "./pages/LoggedInBlogs";
import Learn from "./pages/Learn";
import LiveCharts from "./pages/LiveCharts";
import TotalTermsAndConditions from "./pages/TotalTermsAndConditions";
import SubmitIdea from "./pages/SubmitIdea";

const AppRoutes = () => {
  const ctx = useContext(AuthLogin);

  const [blogItems, setBlogs] = useState([
    { title: 'Blog post title', created_at: '2024-03-20', author: 'Author Name', likes: 156, body: 'testing' },
    { title: 'Another blog post', created_at: '2024-03-19', author: 'Author Name', likes: 342, body: 'testing' },
    { title: 'Interesting article', created_at: '2024-03-18', author: 'Author Name', likes: 89, body: 'testing' },
    { title: 'Tech news update', created_at: '2024-03-17', author: 'Author Name', likes: 267, body: 'testing' },
    { title: 'Latest developments', created_at: '2024-03-16', author: 'Author Name', likes: 178, body: 'testing' },
    { title: 'Industry insights', created_at: '2024-03-15', author: 'Author Name', likes: 445, body: 'testing' },
  ]);

  const fetchBlogsHandler = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getallblogs`);
      const data = await response.json();
      if (!response.ok) {
        throw new Error();
      }

      const loadedBlogs = [];

      // dynamically render blogs from source
      for (const key in data) {
        loadedBlogs.push({
          title: data[key].title,
          created_at: new Date(data[key].created_at).toLocaleDateString(),
          author: data[key].author,
          likes: data[key].likes,
          body: data[key].body
        });
      }

      setBlogs(loadedBlogs);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  //Render once
  useEffect(() => {
    fetchBlogsHandler();
  }, [fetchBlogsHandler]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/About" element={<About />} />
      <Route path="/Pricing" element={<Pricing/>} />
      <Route path="/Contact" element={<Contact/>} />
      <Route path="/SocialMedia" element={<SocialMedia />} />
      <Route path="/my-dashboard" element={ctx.isLoggedIn? <Dashboard /> : <AccessDenied />} />
      <Route path="/add-charts" element={ctx.isLoggedIn? <AddCharts /> : <AccessDenied />} />
      <Route path="/blogged-in" element={ctx.isLoggedIn? <LoggedInBlogs /> : <AccessDenied />} />
      <Route path="/learn" element={ctx.isLoggedIn? <Learn /> : <AccessDenied />} />
      <Route path="/live-charts" element={ctx.isLoggedIn? <LiveCharts /> : <AccessDenied />} />
      <Route path="/blogs" element={<Blogs/>} />
      <Route path="/terms-and-conditions" element={<TotalTermsAndConditions/>} />
      <Route path="/submit-idea" element={<SubmitIdea/>}/>
      
      {/* Dynamic blog post routes */}
      {blogItems.map(blog => (
        <Route
          key={blog.title}
          path={`/blogs/${blog.title.replace(/ /g, '-')}`}
          element={<BlogPost blogData={blog} />}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;