import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

const BlogPost = ({ blogData }) => {
  if (!blogData) {
    return <div>Blog post not found</div>;
  }

  return (
    <>
    <NavBar /> 
    <div className="blog-post-container">
      <h1>{blogData.title}</h1>
      <div className="blog-post-meta">
        <p>Published on: {blogData.created_at}</p>
        <p>Author: {blogData.author}</p>
        <p>{blogData.likes} likes</p>
        <p>{blogData.body}</p>
      </div>
      {/* Add your blog content here */}
    </div>
    <Footer />
    </>
  );
};

export default BlogPost;