import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback,
} from "react";

import AuthLogin from "../profile/auth-login";
import Input from "./Input";

import "../assets/styles/LoginInput.css";

import { useNavigate } from "react-router-dom";

const FINNTECH_API_URL = process.env.REACT_APP_API_URL;

// Reducer to manage username input and validation state
const usernameReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 6 };
  }
  if (action.type === "INPUT_BLUR") {
    return { value: state.value, isValid: state.value.trim().length > 6 };
  }
  return { value: "", isValid: false };
};

// Reducer to manage password input and validation state
const passwordReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 6 };
  }
  if (action.type === "INPUT_BLUR") {
    return { value: state.value, isValid: state.value.trim().length > 6 };
  }
  return { value: "", isValid: false };
};

const LoginInput = (props) => {
  const [loginIsValid, setLoginIsValid] = useState(false); // Validity of form
  const [content, setContent] = useState(null); // Display error messages
  const navigate = useNavigate();

  const [usernameState, dispatchUsername] = useReducer(usernameReducer, {
    value: "",
    isValid: null,
  });

  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: "",
    isValid: null,
  });

  const ctx = useContext(AuthLogin); // Authentication context
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  // Check if form inputs are valid whenever username or password state changes
  useEffect(() => {
    const identifier = setTimeout(() => {
      setLoginIsValid(usernameState.isValid && passwordState.isValid);
    }, 250);

    return () => {
      clearTimeout(identifier);
    };
  }, [usernameState.isValid, passwordState.isValid]);

  const usernameChangeHandler = (event) => {
    dispatchUsername({ type: "USER_INPUT", val: event.target.value });
  };

  const passwordChangeHandler = (event) => {
    dispatchPassword({ type: "USER_INPUT", val: event.target.value });
  };

  const validateUsernameHandler = () => {
    dispatchUsername({ type: "INPUT_BLUR" });
  };

  const validatePasswordHandler = () => {
    dispatchPassword({ type: "INPUT_BLUR" });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (loginIsValid) {
      try {
        const userData = await validateLogin(usernameState.value, passwordState.value);
        // If login is successful, update context and navigate to dashboard
        // console.log(userData[0]);
        if (userData[0]) {
          // console.log(userData[0]);
          // console.log(userData[0].username, userData[0].firstname + " " + userData[0].lastname, userData[0].role, userData[0].user_id);

          
          const firstTimeLogin = await isFirstTimeLogin(userData[0].user_id);
          const userAcceptedTAndC = await userAcceptedTermsAndConditions(userData[0].user_id);
          // console.log("userAcceptedTAndC: " + userAcceptedTAndC);
          ctx.onLogin(userData[0].username, userData[0].firstname + " " + userData[0].lastname, userData[0].role, userData[0].user_id, firstTimeLogin, userAcceptedTAndC);
          const logTheUserLogin = await logUserLogin(userData[0].user_id)
          navigate("/my-dashboard");
          // console.log("USERNAME:")
          // console.log(userData[0].username);
        } else {
          setContent(
            <div className="errormessage">
              Unable to Validate Username and/or password
            </div>
          );
        }
      } catch (error) {
        console.log(error);
        setContent(
          <div className="errormessage">
            Error validating login. Please try again.
          </div>
        );
      }
    } else {
      setContent(
        <div className="errormessage">
          Invalid Username and/or password
        </div>
      );
    }
  };

  // Validate login and fetch additional user data such as role and subscription status
  const validateLogin = useCallback(async (username, password) => {
    const url = `${FINNTECH_API_URL}/username/${username}/password/${password}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
    // const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    // const response = await fetch(url);
    // console.log(response);

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.json();
    // console.log(data);

    // Assuming the API returns an object containing username, role, and subscription status
    return data; // { username: "user", role: "Admin", isSubscribed: true }
  }, []);

  const logUserLogin = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/userLoggedIn/${user_id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
    // const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    // const response = await fetch(url);
    // console.log(response);

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.json();
    // console.log(data);


    return data; 
  }, []);

  const isFirstTimeLogin = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/isUserFirstTimeLogin/${user_id}`;

    // console.log(url);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
    // const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    // const response = await fetch(url);
    // console.log(response);

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.text();
    console.log(data);

    return data; 
  }, []);

  const userAcceptedTermsAndConditions = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/userAcceptedTermsAndConditions/${user_id}`;

    console.log(url);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
    // const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    // const response = await fetch(url);
    // console.log(response);

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.text();
    console.log(data);

    return data; 
  }, []);

  return (
    <>
      {content}
      <div className="dialogue">
        <form onSubmit={submitHandler}>
          <h1>LOGIN</h1>
          <p>Enter Email and Password to Sign In</p>
          <Input
            ref={usernameInputRef}
            value={usernameState.value}
            id="username"
            onChange={usernameChangeHandler}
            onBlur={validateUsernameHandler}
          />
          <br />
          <Input
            ref={passwordInputRef}
            value={passwordState.value}
            id="password"
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
            type="password"
          />
          <br />
          <button className="submitbtn">Log In</button>
          <br />
          <a className="forgot" href="/forgot-password">Forgot Password?</a>
          <br />
          <button className="googlebtn">Continue With Google</button>
        </form>
      </div>
    </>
  );
};

export default LoginInput;
