import React, { useState, useCallback, useEffect } from 'react';
import '../assets/styles/BlogList.css';

const HeartIcon = () => <span className="heart-icon">♡</span>;

const BlogItem = ({ title, created_at, author, likes }) => {
  const handleClick = () => {
    window.location.href = `/blogs/${title.replace(/ /g, '-')}`;
  };

  return (
    <div 
      className="blog-item" 
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="blog-item-content">
        <h3 className="blog-item-title">{title}</h3>
        <p className="blog-item-date">{created_at}</p>
        <p className="blog-item-author">{author}</p>
        <p className="blog-item-likes">{likes} likes</p>
      </div>
      <HeartIcon />
    </div>
  );
};

const BlogList = ({ maxDisplay = 5 }) => {
  const [activeTab, setActiveTab] = useState('newest');
  const [currentPage, setCurrentPage] = useState(1);

  const [blogItems, setBlogs] = useState([
    { title: 'Blog post title', created_at: '2024-03-20', author: 'Author Name', likes: 156, body: 'testing' },
    { title: 'Another blog post', created_at: '2024-03-19', author: 'Author Name', likes: 342, body: 'testing' },
    { title: 'Interesting article', created_at: '2024-03-18', author: 'Author Name', likes: 89, body: 'testing' },
    { title: 'Tech news update', created_at: '2024-03-17', author: 'Author Name', likes: 267, body: 'testing' },
    { title: 'Latest developments', created_at: '2024-03-16', author: 'Author Name', likes: 178, body: 'testing' },
    { title: 'Industry insights', created_at: '2024-03-15', author: 'Author Name', likes: 445, body: 'testing' },
  ]);

  const fetchBlogsHandler = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getallblogs`);
      const data = await response.json();
      if (!response.ok) {
        throw new Error();
      }

      const loadedBlogs = [];

      // dynamically render blogs from source
      for (const key in data) {
        loadedBlogs.push({
          title: data[key].title,
          created_at: new Date(data[key].created_at).toLocaleDateString(),
          author: data[key].author,
          likes: data[key].likes,
          body: data[key].body
        });
      }

      setBlogs(loadedBlogs);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  //Render once
  useEffect(() => {
    fetchBlogsHandler();
  }, [fetchBlogsHandler]);

  const sortedBlogItems = [...blogItems].sort((a, b) => {
    if (activeTab === 'newest') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else {
      return b.likes - a.likes;
    }
  });

  const totalPages = Math.ceil(blogItems.length / maxDisplay);
  const startIndex = (currentPage - 1) * maxDisplay;
  const displayedItems = sortedBlogItems.slice(startIndex, startIndex + maxDisplay);
  const showMoreButton = blogItems.length > maxDisplay && maxDisplay === 5;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="page-container">
      <div className="blog-list-container">
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${activeTab === 'newest' ? 'active' : ''}`}
            onClick={() => {
              setActiveTab('newest');
              setCurrentPage(1);
            }}
          >
            Newest
          </button>
          <button
            className={`toggle-button ${activeTab === 'popular' ? 'active' : ''}`}
            onClick={() => {
              setActiveTab('popular');
              setCurrentPage(1);
            }}
          >
            Popular
          </button>
        </div>
        <div>
          {displayedItems.map((item, index) => (
            <BlogItem key={index} {...item} />
          ))}
        </div>
        {maxDisplay === 5 ? (
          showMoreButton && (
            <div className="show-more-container">
              <a href="/blogs" className="show-more-button">
                Show More Blogs
              </a>
            </div>
          )
        ) : (
          <div className="pagination-container">
            <button 
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous Page
            </button>
            <span className="page-indicator">
              Page {currentPage} of {totalPages}
            </span>
            <button 
              className="pagination-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next Page
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogList;